.opacity-8 {
    opacity: .7;
}

.font-sm {
    font-size: .7rem;
}

.font-weight-600{
    font-weight: 600;
}

.ducument-list {
    padding: 0;
    list-style: none;
}

.ducument-list li {
    width: 14%;
    border: 1px solid #D2DDEC;
    margin-right: 20px;
    height: 90px;
    border-radius: 4px;
}
.list_paginnation{
    list-style: none;
}
.list_paginnation > li > button {
    padding: 13px 18px;
}
.ducument-list li img {
    width: 100%;
    height: 100%;
}
@media screen and (max-width:991px) {
    .ducument-list li {
        width: 25%;
        height:60px;
       
    }
  }

  span.rupees_icon svg {
    width: 16px;
    height: 12px;
    position: relative;
    top: -2px;
    color: rebeccapurple;
    fill: #95AAC9;
}

.border-1{
    border: 1px solid #D2DDEC;
}

.badge {
    padding: .3em 0.8em;
    font-size: 100%;
}

.text-orange{
    color: #e23434;
}

.text-green{
    color: #4fff19;
}

span.dot {
    width: 5px;
    height: 5px;
    background: #e23434;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    top: -3px;
    margin-right: 6px;
}

span.dot_green {
    width: 5px;
    height: 5px;
    background: #4fff19;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    top: -3px;
    margin-right: 6px;
}

.cursor-pointer {
    cursor: pointer;
}

.card-header [aria-expanded="true"] i{
    transform: rotate(180deg);
  }

 .bg-gary {
    background: #edf2f9; 
}

.css-yk16xz-control {
    background-color: #ffffff !important;
    border-color: #D2DDEC  !important;
    min-height: 39px !important;
    border-radius: 0.375rem !important;
  
   
}

input[type=number] {
    -moz-appearance: textfield;
}
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

span.rupees_icon.icon-input svg {
    position: absolute;
    top: 14px;
    left: 10px;
}

.padding-left {
    padding-left: 30px;
}

span.trophy_icon svg {
    margin-right: 11px;
}

.plus_icon {
    position: relative;
    top: 40px;
    color: #152e4d;
}

a.display-content {
    display: contents;
    color: #263241;
}

.large-switch .custom-control-label::before {
    width: 5rem; height: 2.5rem;
    border-radius: 1.75rem;
} 

.large-switch  .custom-control-label::after {
    width: 2rem; top: 0.25rem;
    height: 2rem;
    border-radius: 1rem;
}

.large-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(2.6rem);
}
.table_height {
    height: calc(100vh - 279px);
    overflow: auto;
}

/* Scroll Bar */
.scroll_bar{
    scroll-behavior: smooth;
}
.scroll_bar::-webkit-scrollbar {
    width: 6px;
  }
  
  .scroll_bar::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  .scroll_bar::-webkit-scrollbar-thumb {
    background: #152e4d; 
  }
  .x_scroll_width::-webkit-scrollbar{
    height: 6px;
  }
  .copun_card {
    border: 1px solid #E3EBF6;
}
  .copun_card ul{
      list-style: none;
  }
  .copun_card ul li:first-child {
    color: #95aac9;
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: normal;
    font-weight: 100;
}
.copun_card ul li {
    padding: 1rem;
    border-bottom: 1px solid #E3EBF6;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
}
.copun_card ul li:last-child {
    border-bottom: none;
}
.pr_table tr td{
    text-align: center;

}
.pr_table tr td:first-child{
    text-align: left;

}
.pr_table tr th{
    text-align: center;

}
.pr_table tr th:first-child{
    text-align: left;

}
.contest_table tr td{
    /* white-space: nowrap; */
    width: auto;
}
.pro_date .flatpickr-day.today{
    border-color: #e9e9e9;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: #152E4D;
    background: #152E4D;
    color: #fff !important;
}
  /* End */

/* Select */

.slect_form {
    padding: 10px 12px;
    min-width: 106px;
    width: 100%;
}
.slect_form:focus {
    border:1px solid #D2DDEC
}

.slect_frm {
    padding: 2px 4px;
    min-width: 94px;
    width: 70%;
}
.slect_frm:focus {
    border:1px solid #D2DDEC
}

/* End */




.table-row{
    cursor:pointer;
}

.max-height {
    max-height: 22rem;
}

.min-w-5{
    min-width: 5rem;
}

.text-black{
    color: #12263F;
}

.alert-danger {
    color: #FFFFFF !important;
    background-color: #e63757 !important;
    border-color: #e63757 !important;
}
.alert-success {
    color: #FFFFFF !important;
    background-color: #4BB543 !important;
    border-color: #4BB543 !important;
}

.Toastify__close-button {
    color: #fff !important;
    opacity: 0.9 !important;
}

.Toastify__progress-bar{
    background: #fff !important;
}

/* ----------------------------------------------pagination---------------------------------- */

.pagination{
    justify-content: center;
    height: 3rem;
  }
  .pagination a{
    color: black;
  }
 
  .pagination .disabled a{
    color: gray;
  }

.pagination li{
    font-size: .9375rem; 
    padding: .35rem .1rem;
    background-color: #fff;
    border: 1px solid #e3ebf6;  
}

.pagination li a{
    height:.93rem;
    width:.93rem;
    /* padding: .5rem 1.2rem; */ 
}

.pagination .previous{
    padding: .75rem .25rem;
}


.pagination .previous a{
    padding: .65rem .1rem;
}


.pagination .next{
    padding: .75rem .5rem;
}


.pagination .next a{
    padding: .65rem .1rem;
}

.pagination li.active{
    /* background-color: #152E4D; */
    color: #fff;
}

.pagination li:first-child{
    border-top-left-radius: .5rem;
    line-height: 1.5;
    border-bottom-left-radius: .5rem;
    
}

.pagination li:last-child{
    border-top-right-radius: .5rem; line-height: 1.5;
    border-bottom-right-radius: .5rem;
    
}

@media only screen and (max-width:767px) {
    .pagination-lg .page-link, .pagination-lg .page {
        padding: 0.7rem 1rem !important;
    }
}

/* ----------------------------------------------pagination---------------------------------- */

.live.overline {
    border: 1px solid;
    padding: 0 5px;
    border-radius: 20px;
    font-size: 80%;
}

/* Custom modal CSS */
.modal.rightSidebar.fade.show .modal-dialog {
    transform: translateX(0);
}
.modal.rightSidebar.fade .modal-dialog {
    transform: translateX(100%);
}
.rightSidebar .modal-dialog {
    margin: 0 0 0 auto;
    max-width: 600px;
}
.rightSidebar .modal-content {
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    border: none;
}
.rightSidebar .label_name{
    font-weight: 500;
}
.rightSidebar .main-btn{
    background:#2c7be5 !important;
    color:#fff;
    font-size: 13px;
}

.table_height_custom{
    height: calc(100vh - 149px);
    overflow: auto;
}

.error{
    border: 1px solid red;
}

.err-msg{
    color: red;
    font-size: 12px;
}

.btn-small {
  padding: 0.125rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.15; }

  .font-small {
    font-size: 1rem;
}

span.dot-2 {
    width: 5px;
    height: 5px;
    background: #e23434;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    top: -3px;
    margin-right: 2px;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.hollowBtn{
    border: solid 1px #152E4D;
    background-color: #FFFFFF;
    color: #152E4D;
}


.percentage_icon span{
    width: 16px;
    height: 12px;
    position: relative;
    top: -2px;
    color: rebeccapurple;
    fill: #95AAC9;
}

.percentage_icon.icon-input span{
    position: absolute;
    top: 11px;
    right: 10px;
}
.openmodal{
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

.modalBackground {
    width: 50vw;
    height: 60vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer {
    width: 500px;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }

 .showtype{
   
     color: green;
padding: 8px 12px;
     background-color: #93ce8f;
     border-radius: 10px;
 }
 .live{
    color: #c62424;
    padding: 8px 12px;
    background-color: #efb7b7;
    border-radius: 10px;
 }
 .stocke{
    /* color: green; */
    padding: 8px 12px;
         background-color:rgb(247 219 23);
         border-radius: 10px;
 }
 .crypto{
    padding: 8px 12px;
    background-color:rgb(163 176 244);
    border-radius: 10px; 
 }
 .cancel{
    color: #ffffff;
    padding: 8px 12px;
    background-color: rgb(255 84 84);
    border-radius: 10px;
 }
 .Ended{
    color: #10660d;
    padding: 8px 12px;
    background-color:rgb(191, 241, 134);
    border-radius: 10px;
 }
 .upcoming{
    color: #650c62;
    padding: 8px 12px;
    background-color: rgb(224 181 250);
    border-radius: 10px;
 }

 .Pending{
    color: #5a5514;
    padding: 8px 12px;
    background-color: rgb(243 234 141);
    border-radius: 10px;
 }
 .Rejected{
    color: #772222;
    padding: 8px 12px;
    background-color: rgb(243 152 152);
    border-radius: 10px;
 }
 .Approved{
    color: #183914;
    padding: 8px 12px;
    background-color: rgb(106 186 100);
    border-radius: 10px;
 }
 .Processed{
    color: #eb7200;
    padding: 8px 12px;
    background-color: rgb(224, 162, 127);
    border-radius: 10px;
 }

 .mainModal{
    margin: 52px 0px;

 }
li h4{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}